import { Box, Grid, GridItem, Heading, Image, useMediaQuery, VStack } from '@chakra-ui/react';
import React from 'react';
import velacionPNG from '../../assets/icons/velacion.png';
import anillos from '../../assets/icons/anillos.png';
import lazo from '../../assets/icons/lazo.png';
import arras from '../../assets/icons/arras.png';
// import biblia from '../../assets/icons/biblia.png';

function Padrinos() {

    const [largerScreen] = useMediaQuery('(min-width: 1280px)');

    return (
        <>
        <VStack>
            <Box w='100%' display='grid' placeItems='center' mt='10' mb='4'>
                <Heading as='h1' size={largerScreen ? '2xl' : '2xl' } color='yellow.600' style={{ fontFamily: 'Josefin Slab' }} >Padrinos</Heading>
            </Box>
            <Grid
                h='1100px'
                w='80%'
                templateRows= {largerScreen ? 'repeat(3, 1fr)' : 'repeat(5, 1fr)'}
                templateColumns='repeat(2, 1fr)'
                gap={2}
            >
                <GridItem colSpan={2} pt='2'>
                    <VStack>
                        <Image src={velacionPNG} w='80px'/>
                        <Heading as='h1' size={largerScreen ? '2xl' : 'xl' } color='gray.500' style={{ fontFamily: 'Dancing Script' }} textAlign='center'>Velación</Heading>
                        <Heading as='h1' size={largerScreen ? 'xl' : 'lg' } color='yellow.600' style={{ fontFamily: 'Josefin Slab' }} textAlign='center'>Adalgiso Tello Cedillo &amp; Sofía Esquivel Garibay </Heading>
                        
                    </VStack>
                </GridItem>

                <GridItem colSpan={largerScreen ? 1 : 2} pt='6'>
                    <VStack>
                        <Image src={anillos} w='80px'/>
                        <Heading as='h1' size={largerScreen ? '2xl' : 'xl' } color='gray.500' style={{ fontFamily: 'Dancing Script' }} textAlign='center'>Anillos</Heading>
                        <Heading as='h1' size={largerScreen ? 'xl' : 'lg' } color='yellow.600' style={{ fontFamily: 'Josefin Slab' }} textAlign='center'>Juvenal Domínquez Acuna &amp; Giancarla Mayonga Aguilar</Heading>
                    </VStack>
                </GridItem>
                <GridItem colSpan={largerScreen ? 1 : 2} pt='6'>
                    <VStack>
                        <Image src={lazo} w='80px'/>
                        <Heading as='h1' size={largerScreen ? '2xl' : 'xl' } color='gray.500' style={{ fontFamily: 'Dancing Script' }} textAlign='center'>Lazo</Heading>
                        <Heading as='h1' size={largerScreen ? 'xl' : 'lg' } color='yellow.600' style={{ fontFamily: 'Josefin Slab' }} textAlign='center'>Elido Mota Barajas &amp; Laila Hernádez Sandoval</Heading>
                    </VStack>
                </GridItem>
                {/* <GridItem colSpan={largerScreen ? 1 : 2} pt='6'>
                    <VStack>
                        <Image src={biblia} w='50px'/>
                        <Heading as='h1' size={largerScreen ? '2xl' : 'xl' } color='gray.500' style={{ fontFamily: 'Dancing Script' }} textAlign='center'>Biblia y Rosario</Heading>
                        <Heading as='h1' size={largerScreen ? 'xl' : 'lg' } color='yellow.600' style={{ fontFamily: 'Josefin Slab' }} textAlign='center'>John Doe &amp; John Doe</Heading>
                    </VStack>
                </GridItem> */}
                <GridItem colSpan={largerScreen ? 1 : 2} pt='6'>
                    <VStack>
                        <Image src={arras} w='80px' />
                        <Heading as='h1' size={largerScreen ? '2xl' : 'xl' } color='gray.500' style={{ fontFamily: 'Dancing Script' }} textAlign='center'>Arras</Heading>
                        <Heading as='h1' size={largerScreen ? 'xl' : 'lg' } color='yellow.600' style={{ fontFamily: 'Josefin Slab' }} textAlign='center'>Ammiel Jimínez Apodaca &amp; Emelinda Alicea Polanco</Heading>
                    </VStack>
                </GridItem>
            </Grid>
        </VStack>
        </>
  )
}

export default Padrinos;
