import React from 'react'
import { Box, Heading, Icon, Link } from '@chakra-ui/react'
import { BsInstagram } from 'react-icons/bs'

function Instagram() {
  return (
    <>
        <Box h='400px' w='100%' display='grid' placeItems='center' p='3' bgColor="#4158D0" bgImage="linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%)">
            <Heading as='h3' size='md' color='white' style={{ fontFamily: 'Josefin Slab' }} textAlign='center'>Durante este gran día, sube tus fotos a tu red social favorita con nuestro hashtag:</Heading>
            <Icon as={BsInstagram} color='white' w={40} h={40}/>
            <Heading as='h3' size='xl' color='white' style={{ fontFamily: 'Josefin Slab' }} textAlign='center'>#BodaMaríayHernán</Heading>
            <Link href='https://www.instagram.com/explore/tags/bodaaleygil/' color='white' fontSize='xl' pb='2'>Ver en Instagram</Link>
        </Box>
    </>
  )
}

export default Instagram