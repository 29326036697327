import React from 'react';
import Carousel  from 'nuka-carousel';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import photo1 from '../../assets/photo1.jpg';
import photo2 from '../../assets/photo2.jpg';
import photo3 from '../../assets/photo3.jpg';
import photo4 from '../../assets/photo4.jpg';
import photo5 from '../../assets/photo5.jpg';
import './carousel.css';
import Tick from './countdown';
import { Box } from '@chakra-ui/react';

export default class ImageSlide extends React.Component {
  
  render() {
    return (
      <>
      <Box w='100%' h='732px' overflow='hidden' m='0' filter='auto' brightness='95%'>
        <Carousel
            renderCenterLeftControls={({ previousSlide }) => (
                <button onClick={previousSlide}><FaArrowLeft /></button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
                <button onClick={nextSlide}><FaArrowRight /></button>
            )}
            autoplay={true}
            wrapAround ={true}
            autoplayInterval={3000 }
        >
          
          <img src={photo1} alt='Foto 1' style={{width: "100%", height:"750px", objectFit: "cover"}} />
          <img src={photo2} alt='Foto 2' style={{width: "100%", height:"750px", objectFit: "cover"}} />
          <img src={photo3} alt='Foto 3' style={{width: "100%", height:"750px", objectFit: "cover"}} />
          <img src={photo4} alt='Foto 4' style={{width: "100%", height:"750px", objectFit: "cover"}} />
          <img src={photo5} alt='Foto 5' style={{width: "100%", height:"750px", objectFit: "cover"}} />
        </Carousel>
        <Tick countdownTimestampMs={1743300000000}/>
      </Box>
      </>
    );
  }
}