import { Heading, Text, VStack, Box } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";

const Invitados = () => {
    const {id} = useParams();
    const {quantity} = useParams();

    

    return (
        <VStack bgImage="url('https://static.vecteezy.com/system/resources/previews/004/782/953/non_2x/watercolor-splash-pink-and-gold-glitter-background-vector.jpg')" bgSize='cover' bgPosition="center" bgRepeat='no-repeat'>
            <Heading size='xl' color='yellow.600' style={{ fontFamily: 'Dancing Script' }} textAlign='center' mb='4' mt='12'>Datos de los invitados</Heading>
            <Heading size='2xl' color='gray.600' style={{ fontFamily: 'Josefin Slab' }} textAlign='center' mb='4'>{id}</Heading>
            <Box display='grid' placeItems='center' p='4'>
                <Heading size='xl' color='gray.600' style={{ fontFamily: 'Josefin Slab' }} mr='2'>Número de boletos: </Heading>
                <Box borderWidth='4px' borderRadius='lg' mt='3' p='4'><Heading size='3xl' color='gray.600' bgColor='white' style={{ fontFamily: 'Josefin Slab'}} textAlign='center'>{quantity}</Heading></Box>
            </Box>
            <Text fontSize='3xl' color='gray.600' style={{ fontFamily: 'Josefin Slab' }} pb='4'>No niños</Text>
        </VStack>
    )};

export default Invitados;