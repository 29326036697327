import { Box, Grid, GridItem, Heading, useMediaQuery, Text, VStack } from '@chakra-ui/react';
import React from 'react';

function Nombres() {

    const [screen1280] = useMediaQuery('(min-width: 1280px)');

  return (
    <VStack>
    <Box w='100%' display='grid' placeItems='center' pt='8' pb='10' bgColor='gray.500'>
        <Text as='cite' color='white'textAlign='center'>“Amar no es mirarse el uno al otro; es mirar juntos en la misma dirección”</Text>
        <Text color='white'>-Antonie de Saint-Exupéry</Text>
    </Box>
    <Box w='100%' display='grid' placeItems='center' mt='10'>
        <Heading size='xl' color='gray.600' style={{ fontFamily: 'Josefin Slab' }} mb='4' mt='8'>NOSOTROS</Heading>
    </Box>
    
    <Grid
        minHeight='100px'
        w='70%'
        templateRows= {screen1280 ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)'}
        templateColumns='repeat(3, 1fr)'
        gap={1}
    >
        <GridItem colSpan={screen1280 ? 1 : 3}>
            <VStack>
                <Heading as='h1' size='3xl' color='yellow.600' style={{ fontFamily: 'Dancing Script' }} textAlign='center'>María</Heading>
            </VStack>
        </GridItem>
        <GridItem colSpan={screen1280 ? 1 : 3}>
            <VStack>
                <Heading as='h1' size='3xl' color='yellow.600' style={{ fontFamily: 'Josefin Slab' }} textAlign='center'>&amp;</Heading>
            </VStack>
        </GridItem>
        <GridItem colSpan={screen1280 ? 1 : 3}>
            <VStack>
                <Heading as='h1' size='3xl' color='yellow.600' style={{ fontFamily: 'Dancing Script' }} textAlign='center'>Hernán</Heading>
            </VStack>
        </GridItem>
    </Grid>
</VStack>
  )
}

export default Nombres;
