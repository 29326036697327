import { Box, Button, Divider, Heading, HStack, Icon, Image, Link, Menu, MenuButton, MenuItem, MenuList, SimpleGrid, useMediaQuery, VStack } from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';
import { BsApple } from 'react-icons/bs';
import { SiMicrosoftoutlook } from 'react-icons/si';
import React from 'react';
import CustomModal from './Modal';
import flores01 from '../../assets/flores-01.svg'
import flores02 from '../../assets/flores-02.svg'
import receptionPhoto from '../../assets/mansion-tequila.jpg'
import temploPhoto from '../../assets/templo.webp'

export default function Rendezvous() {
    const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)');
    
    return (
        <VStack>
            <Box w='100%' display='grid' placeItems='center' mt='10' mb='6'>
                <Heading as='h1' size={isLargerThan1280 ? '3xl' : '2xl' } color='yellow.600' style={{ fontFamily: 'Dancing Script' }} >Dónde y Cuándo:</Heading>
            </Box>
            <Image src={flores01} w={isLargerThan1280 ? '300px' : '80px'} style={{ position: 'absolute', left: '0', zIndex: '0' }}/>
            <Image src={flores02} w={isLargerThan1280 ? '300px' : '80px'} style={{ position: 'absolute', right: '0', zIndex: '0' }}/>
            <SimpleGrid w="75%" minChildWidth="200px" spacing='20px' placeItems="center" style={{ zIndex: '1'}}  p='3'>
                <Box maxW={isLargerThan1280 ? 'sm' : 'xs'} borderWidth='1px' borderRadius='lg' overflow='hidden' >
                    <Image src={temploPhoto} alt='Fotografía del Templo Expiatorio del Santísimo Sacramento' style={{width: "100%", height:"258px", objectFit: "cover"}}/>

                    <Box p='3' bgColor='white'>
                        <Box display='flex' alignItems='baseline'>
                                <Heading size='md' fontWeight='black'>CEREMONIA RELIGIOSA</Heading>
                        </Box>
                        <Divider />
                        <Box
                            mt='2'
                            fontWeight='bold'
                            as='h3'
                            lineHeight='tight'
                        >
                            Templo Expiatorio del Santísimo Sacramento
                        </Box>
                        <Box color='gray.600' mt='0.5'>
                            <Box as='span' fontWeight='semibold' fontSize='md'>
                                Cuándo:
                            </Box>
                            29 de Marzo, 2025; 19:00 Hrs.
                        </Box>

                        <Box color='gray.600'>
                            <Box as='span' fontWeight='semibold' fontSize='md'>
                                Dirección:
                            </Box>
                            Calle Manuel López Cotilla 935, Col. Americana, 44160 Guadalajara, Jal.
                        </Box>
                        <HStack mt='6' justifyContent='center'>
                            <CustomModal
                                showModalButtonText='Ver mapa'
                                modalHeader='CEREMONIA RELIGIOSA'
                                modalSrc='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7465.866612039147!2d-103.3661329730297!3d20.672292194770137!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ae0283dde105%3A0x2319ae110b6fa64e!2sTemplo%20Expiatorio%20del%20Sant%C3%ADsimo%20Sacramento!5e0!3m2!1ses-419!2smx!4v1616981304775!5m2!1ses-419!2smx'
                                modalTitle='Templo Expiatorio del Santísimo Sacramento'
                                modalDate='29 de Marzo, 2025; 19:00 Hrs.'
                                modalAddress='Calle Manuel López Cotilla 935, Col. Americana, 44160 Guadalajara, Jal.' />
                            <Menu>
                                <MenuButton as={Button}>
                                    Agregar a calendario
                                </MenuButton>
                                <MenuList>
                                    <MenuItem minH='48px' as={Link} style={{ textDecoration: 'none' }} href='' isExternal>
                                        <Icon as={FcGoogle} boxSize='2rem' mr='12px'/>
                                        Google Calendar
                                    </MenuItem>
                                    <MenuItem minH='48px' as={Link} style={{ textDecoration: 'none' }} href='/'>
                                        <Icon as={BsApple} boxSize='2rem' mr='12px' />
                                        Apple Calendar
                                    </MenuItem>
                                    <MenuItem minH='48px' as={Link} style={{ textDecoration: 'none' }} href='/'>
                                        <Icon as={SiMicrosoftoutlook} boxSize='2rem' mr='12px' />
                                        Outlook
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                    </Box>
                </Box>
                <Box maxW={isLargerThan1280 ? 'sm' : 'xs'} borderWidth='1px' borderRadius='lg' overflow='hidden'>
                    <Image src={receptionPhoto} alt='Las Calandrias Eventos' style={{width: "100%", height:"258px", objectFit: "cover"}}/>

                    <Box p='3' bgColor='white'>
                        <Box display='flex' alignItems='baseline'>
                                <Heading size='md' fontWeight='black'>RECEPCIÓN</Heading>
                        </Box>
                        <Divider />
                        <Box
                            mt='2'
                            fontWeight='bold'
                            as='h3'
                            lineHeight='tight'
                            isTruncated
                        >
                            Casa de los Abanicos
                        </Box>
                        <Box color='gray.600' mt='0.5'>
                            <Box as='span' fontWeight='semibold' fontSize='md'>
                                Cuándo:
                            </Box>
                            29 de marzo, 2025; 20:00 Hrs.
                        </Box>

                        <Box color='gray.600'>
                            <Box as='span' fontWeight='semibold' fontSize='md'>
                                Dirección:
                            </Box>
                            Libertad 1823, Col Americana, 44100 Guadalajara, Jal.
                        </Box>
                        <HStack mt='6' justifyContent='center'>
                            <CustomModal
                                showModalButtonText='Ver mapa'
                                modalHeader='RECEPCIÓN'
                                modalSrc='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.9295191435426!2d-103.36561331679837!3d20.672446239467895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ae047c1229e1%3A0xb72832110a2a2d62!2sCasa%20de%20los%20Abanicos!5e0!3m2!1ses-419!2smx!4v1616982219785!5m2!1ses-419!2smx'
                                modalTitle='Casa de los Abanicos'
                                modalDate='29 de marzo, 2025; 20:00 Hrs.'
                                modalAddress='Libertad 1823, Col Americana, 44100 Guadalajara, Jal.' />
                            <Menu>
                                <MenuButton as={Button}>
                                    Agregar a calendario
                                </MenuButton>
                                <MenuList>
                                    <MenuItem minH='48px' as={Link} style={{ textDecoration: 'none' }} href='' isExternal>
                                        <Icon as={FcGoogle} boxSize='2rem' mr='12px'/>
                                        Google Calendar
                                    </MenuItem>
                                    <MenuItem minH='48px' as={Link} style={{ textDecoration: 'none' }} href='/'>
                                        <Icon as={BsApple} boxSize='2rem' mr='12px' />
                                        Apple Calendar
                                    </MenuItem>
                                    <MenuItem minH='48px' as={Link} style={{ textDecoration: 'none' }} href='/'>
                                        <Icon as={SiMicrosoftoutlook} boxSize='2rem' mr='12px' />
                                        Outlook
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                    </Box>
                </Box>
            </SimpleGrid>
            {/* <Box w='100%' display='grid' placeItems='center' pt='6' pb='6' bgColor='gray.400'>
               <Text color='white' textAlign='center' pl='2' pr='2'>"El amor no consiste en mirarse el uno al otro, si no, ver juntos en la misma dirección."</Text>
               <Text color='white' textAlign='center' pl='2'>-Antoine de Saint-Exupéry</Text>
            </Box> */}
        </VStack>
    )
};