import { Box, Grid, GridItem, Heading, Image, useMediaQuery, VStack } from '@chakra-ui/react';
import React from 'react';
import title01 from '../../assets/title-01-gray.svg';
import title02 from '../../assets/title-02-gray.svg';

function Papas() {
    const [desktopScreen] = useMediaQuery('(min-width: 1280px)');
    return (
    <VStack>
        <Box w='100%' display='grid' placeItems='center' mb='1' justifyItems='center' p='4'>
            <Heading as='h1' size='xl' color='gray.600' style={{ fontFamily: 'Josefin Slab' }} textAlign='center'>Con la bendición de Dios y la compañía de nuestros padres</Heading>
        </Box>
        <Grid
                minHeight='200px'
                w='80%'
                templateRows= {desktopScreen ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)'}
                templateColumns='repeat(3, 1fr)'
                gap={1}
            >
                <GridItem colSpan={desktopScreen ? 1 : 3}>
                    <VStack>
                        <Heading as='h1' size='lg' color='yellow.600' style={{ fontFamily: 'Josefin Slab' }} textAlign='center'>Armando Ávalos Gallegos</Heading>
                        <Heading as='h1' size='lg' color='yellow.600' style={{ fontFamily: 'Josefin Slab' }} textAlign='center'>&amp;</Heading>
                        <Heading as='h1' size='lg' color='yellow.600' style={{ fontFamily: 'Josefin Slab' }} textAlign='center'>Jésica Jaimes Rael</Heading>
                    </VStack>
                </GridItem>
                <GridItem colSpan={desktopScreen ? 1 : 3}>
                    <Box h='100%' display='flex' alignItems='center' justifyContent='center'>
                        <Image src={desktopScreen ? '' : title01} w='120px' />
                        <Image src={desktopScreen ? '' : title02} w='120px' />
                    </Box>
                </GridItem>
                <GridItem colSpan={desktopScreen ? 1 : 3}>
                    <VStack>
                        <Heading as='h1' size='lg' color='yellow.600' style={{ fontFamily: 'Josefin Slab' }} textAlign='center'>Agustín Laureano Farías</Heading>
                        <Heading as='h1' size='lg' color='yellow.600' style={{ fontFamily: 'Josefin Slab' }} textAlign='center'>&amp;</Heading>
                        <Heading as='h1' size='lg' color='yellow.600' style={{ fontFamily: 'Josefin Slab' }} textAlign='center'>Myrna Rosas Covas</Heading>
                    </VStack>
                </GridItem>
        </Grid>
        <Box w='100%' display='grid' placeItems='center' mb='1' p='3' justifyItems='center'>
            <Heading as='h1' size='xl' color='gray.600' style={{ fontFamily: 'Josefin Slab' }} textAlign='center'>Tenemos el honor de invitarlos a nuestro enlace matrimonial</Heading>
        </Box>
    </VStack>
  )
}

export default Papas;
