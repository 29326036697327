import React from 'react';
import { VStack, Box, Heading, Image, HStack, Text, useMediaQuery, Button, Link, Icon } from '@chakra-ui/react';
import title01 from '../../assets/title-01.svg';
import title02 from '../../assets/title-02.svg';
import { BsWhatsapp } from 'react-icons/bs';

function ConfirmarWhatsapp() {

  const [IsLargerScreen] = useMediaQuery('(min-width: 1280px)');

  return (
    <VStack bg='gray.500' pb='6'>
        <Box w='100%' display='grid' placeItems='center' mt='10' mb='6'>
           <HStack>
                <Image src={title01} w={IsLargerScreen ? '200px' : '60px'} />
                <Heading as='h1' size={IsLargerScreen ? '3xl' : 'xl'} color='gray.50' style={{ fontFamily: 'Dancing Script' }}>Confirmar asistencia</Heading>
                <Image src={title02}w={IsLargerScreen ? '200px' : '60px'} />
           </HStack>
           <Text p='3' color='white' textAlign='center'>Es muy importante para nosotros contar con tu confirmación lo antes posible</Text>
           <Button borderRadius='xl' w={IsLargerScreen ? '60%' : '80%'} h='4rem' colorScheme='whatsapp' as={Link} href='https://api.whatsapp.com/send/?phone=523320100719&text=%C2%A1Quiero%20mi%20invitaci%C3%B3n!%20Cont%C3%A1ctame%20lo%20antes%20posible.' isExternal><Icon as={BsWhatsapp} boxSize={8} color='white' mr={2} /><Heading size='md' color='white' >Confirmar asistencia</Heading></Button>

        </Box>
    </VStack>
  )
}

export default ConfirmarWhatsapp