import React from 'react';
import Papas from './papas';
import Nombres from './nombres';

function Personas() {
    return (
        <>
            <Nombres />
            <Papas />
        </>
    )
}

export default Personas;
