import React from 'react'
import photo3 from '../assets/photo3.jpg';
import { Box, Heading, VStack } from '@chakra-ui/react'

function FotoFinal() {
  return (
    <>
      <Box w='100%' h='392px' overflow='hidden' m='0' filter='auto' brightness='85%' bgImage={photo3} bgSize='cover' bgPosition="center" bgRepeat='no-repeat'>
        <VStack w='100%' pt='220px' >
        <Heading as='h1' size="lg" className='names' style={{ fontFamily: 'Josefin Slab' }} textAlign='center' textShadow='2px 1px 4px #00000096' >MARÍA &amp; HERNÁN </Heading>
        <Heading as='h1' size="2xl" style={{ fontFamily: 'Dancing Script', color: 'white' }} textAlign='center' textShadow='2px 1px 4px #00000096'>¡Te esperamos!</Heading>
        </VStack>
        </Box>
    </>
  )
}

export default FotoFinal