import './App.css';
import { Analytics } from '@vercel/analytics/react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'; 
import ImageSlide from './components/carousel/carousel';
import { Box, VStack, extendTheme, ChakraProvider, useMediaQuery } from '@chakra-ui/react';
import Rendezvous from './components/rendezvous/Rendevous';
import GiftTable from './components/giftTable/GiftTable';
import Personas from './components/personas/personas';
// import Hoteles from './components/hoteles/hoteles';
import Dresscode from './components/dresscode';
import ConfirmarWhatsapp from './components/confirmacion/whatsapp';
import Padrinos from './components/personas/Padrinos';
import Invitados from './components/Invitados';
import Instagram from './components/instagram';
import FotoFinal from './components/fotoFinal';
import AudioComponent from './components/audioComponents/AudioComponent';


export function IsDesktopScreen(){
  const [mobileScreen] = useMediaQuery('(min-width: 1280px)');
  return mobileScreen;
};

function App() {


  const config = {
    initialColorMode: 'light',
    useSystemColorMode: false,
  }

  const theme = extendTheme({config})
  
  return (
    <ChakraProvider theme={theme}>
      <div className="App">
        <VStack>
          <Box padding='0' bg='orange.50' w='100vw' h='100%'>
            <AudioComponent />
            <ImageSlide />
            <Personas />
            <Rendezvous />
            <Router>
              <Routes>
                <Route path='/p:id/n:quantity' exact element={<Invitados />} />
              </Routes>
            </Router>
            {/* <Hoteles /> */}
            <Padrinos />
            <ConfirmarWhatsapp />
            <Dresscode />
            <GiftTable />
            <Instagram />
            <FotoFinal />
          </Box>
        </VStack>
        <Analytics />
      </div>
    </ChakraProvider>
    
  );
}

export default App;
