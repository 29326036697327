import { Box, Button, Icon, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import './AudioComponents.css';
import song from '../../assets/music.mp3';
import { MdMusicNote, MdMusicOff } from 'react-icons/md';
import { useRef } from 'react';
import { useEffect } from 'react';


function AudioComponent() {
    const toast = useToast(); 
    const id = 'music';

    useEffect(() => {
        toast({
            title: '¡Enciende la música!',
            id,
            variant: 'subtle',
            description: 'Da clic en "♩" para escuchar la música',
            status: 'info',
            duration: 5000,
            position: 'bottom-right',
            isClosable: true,
          });
    }, []);

    const [isPlaying, setIsPlaying] = useState(false);
    const songRef = useRef();


    function startAudio() {
        songRef.current.play();
        setIsPlaying(true);
    }

    function pauseAudio(){
        songRef.current.pause();
        setIsPlaying(false);
    }

    const playMusic = () => {
        songRef.current.play();
        setIsPlaying(true);
    } 

    return (
        <>
        <audio src={song} ref={songRef} />
        <Box className='audioButton' borderLeftRadius='lg'>
            { isPlaying ? (
                <Button colorScheme='red' w='100%' h='90%' onClick={pauseAudio}>
                    <Icon as={MdMusicNote} color='white' boxSize={8} />
                </Button>
            ) : (
                <Button colorScheme='red' w='100%' h='90%' onClick={startAudio}>
                    <Icon as={MdMusicOff} color='white' boxSize={8} />
                </Button>
                )
            }
            
        </Box>
        </>
    )
};

export default AudioComponent